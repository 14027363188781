<template>
    <div class="body fixed mobile">
        <!-- class: fixed, mobile -->
        <!-- Loading Spinner -->
        <!-- <div class="spinner"><div><div></div></div></div> -->
        <!-- Header -->
        <section class="header notice">
            <div class="flexH width">
                <a class="icon iconButton third" @click.prevent="$router.push('/home')">
                    <font-awesome-icon icon="fa-solid fa-chevron-left" size="lg"></font-awesome-icon>
                </a>
            </div>
            <span class="fL nowrap">通知中心</span>
            <div class="flexH width right">
                <!-- NOTE: Howard's fix: 暫時隱藏全部已讀功能 -->
                <a class="textButton fM third" @click.prevent="readAll()">全部已讀</a>
            </div>
        </section>
        <!-- Main -->
        <section class="main notice">
            <!-- Filter -->
            <!-- <div class="filter flexH width">
                <div class="flexH width middle">
                    <div class="dropdown rounded disabled">
                        <select name="type" class="fM rounded">
                            <option value="全部通知">全部通知</option>
                            <option value="系統通知">系統通知</option>
                            <option value="其他通知">其他通知</option>
                        </select>
                        <div class="arrow third">
                            <font-awesome-icon icon="fas fa-caret-down" size="xs"></font-awesome-icon>
                        </div>
                    </div>
                </div>
                <div class="lineV"></div>
                <div class="flexH width middle">
                    <div class="dropdown rounded disabled">
                        <select name="type" class="fM rounded">
                            <option value="新到舊">新到舊</option>
                            <option value="舊到新">舊到新</option>
                        </select>
                        <div class="arrow third">
                            <font-awesome-icon icon="fas fa-caret-down" size="xs"></font-awesome-icon>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- <div class="notify">
                <span>文章通知</span>
                <label class="switch">
                    <input type="checkbox" checked>
                    <span class="slider round"></span>
                </label>
            </div> -->
            <!-- 紀錄 -->
            <div class="records flexV width padding">
                <div
                    v-for="(notification, index) in notifications"
                    :key="'notification' + index"
                >
                    <a
                        class="record flexH width margin"
                        @click.stop="readItem(notification.id)"
                    >
                        <div
                            :class="['dot', { active: notification.unread }]"
                        ></div>
                        <div class="flexV width">
                            <span class="fL">{{ notification.title }}</span>
                            <span class="fM double" v-html="notification.text"></span>
                            <span class="fS gray">{{
                                tsToDatetime(notification.ts)
                            }}</span>
                        </div>
                        <!-- <div class="img">
                            <img src="@/assets/icon/point.svg" />
                        </div> -->
                    </a>
                    <div class="lineH"></div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { db } from "../db.js";
import moment from "moment-timezone";
import { mapState } from "vuex";

export default {
    name: "",
    data() {
        return {
            // notifications: [],
            latestReadAt: 0
        };
    },
    beforeRouteEnter(to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        console.log(to, from);
        next((vm) => {
            let that = vm;
            if (that.user && that.user.userId) {
                //
            } else {
                // window.location.reload();
                next("/home");
            }
        });
    },
    mounted() {
        console.log("mounted!");
        // this.onSnapShot();

        //NOTE: Howard's fix: 現在notification直接從store取用，不用再原地去Firestore撈了
        // this.getLatestReadTime()
        // .then(() => {
        //     this.onSnapShot();
        // });

        setTimeout(() => {
            console.log('Notifications', this.notifications);
        }, 4000)
    },
    computed: {
        ...mapState(["user"]),
        // ...mapGetters({
        //     notifications: 'noti/notifications'
        // }),
        notifications() {
            let ns = this.$store.getters['noti/notifications'];
            return Object.values(ns).sort((a, b) => {
                return b.ts - a.ts;
            });
        }
    },
    methods: {
        compare(a, b) {
            if (a.ts > b.ts) {
                return -1;
            } else if (a.ts == b.ts) {
                return 0;
            } else if (a.ts < b.ts) {
                return 1;
            }
        },
        goBack() {
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push("/");
        },
        tsToDatetime(ts) {
            return moment(ts * 1000).format("YYYY-MM-DD HH:mm:ss");
        },
        onSnapShot() {
            let collection = db.collection(
                `notifications/tycard2022/topics/${this.user.user.firestoreChannelId}/messages`
            );
            // doesn't use change to vuefire bind
            collection.onSnapshot((snapshot) => {
                this.notifications = [];
                snapshot.forEach((doc) => {
                    const data = doc.data();
                    this.notifications.push(
                        Object.assign({}, data, { docId: doc.id, unread: data.unread && (data.ts > this.latestReadAt) })
                    );
                });
                this.notifications.sort(this.compare);
                console.log("this.notifications: ", this.notifications)
                this.getLatestReadTime();
            });
        },
        getLatestReadTime() {
            return db.collection(`notifications/tycard2022/recipients/${this.user['userId']}/readTopics`)
            .doc(this.user.user.firestoreChannelId)
            .get()
            .then(snapshot => {
                if (!snapshot.exists) return;
                this.latestReadAt = snapshot.data().latestReadAt;
            });
        },
        updateUnreads(_latestReadAt) {
            if (_latestReadAt)
                this.latestReadAt = _latestReadAt;
            this.notifications.forEach(noti => {
                if (noti.ts <= this.latestReadAt)
                    noti.unread = false;
            });
        },
        readItem(docId) {
            // let collection = db.collection(
            //     `notifications/tycard2022/topics/${this.user.user.firestoreChannelId}/messages`
            // );
            // let document = collection.doc(docId);
            // return db.runTransaction((transaction) => {
            //     return transaction.get(document).then((doc) => {
            //         transaction.update(document, {
            //             unread: false
            //         });
            //         return doc;
            //     });
            // });
            //NOTE: Howard's fix: use API to mark read instead of writing Firestore directly.
            let config = {
                url: `${process.env.VUE_APP_API_HOST}/air3/v3/merchants/tycard2022/firestore-mark-read-legacy`,
                method: 'POST',
                data: {
                    topicId: this.user.user.firestoreChannelId,
                    messageId: docId
                }
            }
            return this.$http(config);
        },
        readAll() {
            // let collection = db.collection(
            //     `notifications/tycard2022/topics/${this.user.user.firestoreChannelId}/messages`
            // );
            // let batch = db.batch();
            // collection
            //     .get()
            //     .then((querySnapshot) => {
            //         querySnapshot.forEach((doc) => {
            //             let docRef = collection.doc(doc.id);
            //             batch.update(docRef, {unread: false});
            //         });
            //         batch.commit()
            //             .then(() => {
            //                 console.log("Read All!");
            //             })
            //     })
            //     .catch((error) => {
            //         console.log("Error getting documents: ", error);
            //     });
            //NOTE: Howard's fix: use API to mark all read instead of writing Firestore directly.
            console.log('this.user', this.user)
            let config = {
                url: `${process.env.VUE_APP_API_HOST}/air3/v3/merchants/tycard2022/firestore-mark-topic-read`,
                method: 'POST',
                data: {
                    topicId: this.user.user.firestoreChannelId,
                    uuid: this.user['userId']
                }
            }
            this.updateUnreads(moment().unix());//Manually mark all current notifications "read".
            this.$store.commit('noti/setUnreadCount', 0);
            return this.$http(config);
        }
    }
};
</script>

